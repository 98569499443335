
<template>
  <div class="main-content">


    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="row">
      <div class="col-md-6">
        <h4>Filters:</h4>
      </div>
      <div class="col-md-6">

        <button class="btn btn-success float-right" v-b-modal.export-customers> Export </button>

      </div>
    </div>

    <hr/>

    <div class="row" style="margin-bottom: 20px;">
      <div class="col-md-6">
        <div>
          By registration date:
        </div>

        <date-picker   :lang="lang" v-model="start_date" valueType="format" :first-day-of-week="1"></date-picker> to
        <date-picker   :lang="lang" v-model="end_date" valueType="format" :first-day-of-week="1"></date-picker> &nbsp;&nbsp;

      </div>
      <div class="col-md-6">

      </div>
    </div>

    <br/>
    <b-table striped hover :items="customers" :fields="columns">
      <template v-slot:cell(loyaltyId)="data">
        {{ data.value }}
      </template>
      <template v-slot:cell(status)="data">
            <span  class="badge badge-success"  v-if="data.value == 'ACTIVE'">
                   {{ data.value }}
                </span>
        <span class="badge badge-danger"  v-else >
                  {{ data.value }}
            </span>
      </template>
      <template v-slot:cell(options)="data">

        <b-dropdown text="options">
          <b-dropdown-item href="#"  @click="openEditCustomerModal(data.item)">Edit</b-dropdown-item>
          <b-dropdown-item href="#" v-if="data.item.status == 'ACTIVE'" @click="openUpdateCustomerStatus('SUSPENDED', data.item)"  >Deactivate</b-dropdown-item>
          <b-dropdown-item href="#" v-else @click="openUpdateCustomerStatus('ACTIVE', data.item)"  >Activate</b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <div class="row">
      <div class="col-md-12">

        <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
          <ul class="pagination justify-content-center">
            <li v-if="pagination.current_page > 1" class="page-item"><a @click.prevent="changePage(pagination.current_page - 1)" class="page-link" href="#">Previous</a></li>
            <li v-for="page in pagesNumber"
                v-bind:class="[ page == isActived ? 'active' : '']" class="page-item"><a @click.prevent="changePage(page - 1)" class="page-link" href="#">{{ page }}</a></li>
            <li v-if="pagination.current_page < pagination.last_page"  class="page-item"><a  @click.prevent="changePage(pagination.current_page + 1)"class="page-link" href="#">Next</a></li>
          </ul>
        </nav>
      </div>
    </div>



    <b-modal id="export-customers" title="Export customers" @cancel="true" @ok="exportToExcel"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Please enter your email address:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="email_address" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>

  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import CodeInput from "vue-verification-code-input"

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'


export default {
  data() {
    return {
      phoneNumberDuplicate:"",
      phoneNumber:"",
      offset: 4,
      pref:"254",
      disabled:false,
      isLoading: false,
      fullPage: true,
      columns:["loyaltyId","name","mobileNumber","email","gender","dob","created", "status"],
      customers:[],
      ratings:[],
      rating_columns:["customer","rating","comments"],
      duplicate_columns:["name","phoneNumber","cashBalance","duplicateType","options"],
      genders:["Male","Female"],
      ratingsStats:{
        cumulativeRating:0,
      },
      email_address:"",
      start_date:"all",
      end_date:"all",
      customer:{
        name:"",
        mobileNumber:"",
        email:"",
        password:"N/A",
        dob:"",
        gender:"",
        id:0
      },
      phone_verified:false,
      verificationCode:"",
      customerVerificationCode:"",
      pagination: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      status:"",
      duplicates:[],
      duplicate:{
        id:0,
        name:"",
        phoneNumber:"",
        duplicateType:"Phone number",
        cashBalance:""
      }
    };
  },
  components: {
    Loading,
    DatePicker,
    CodeInput
  },
  computed:{
    isActived: function () {
      return this.pagination.current_page + 1;
    },
    pagesNumber: function () {
      if (!this.pagination.to) {
        return [];
      }
      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset * 2);
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    }
  },
  mounted (){
    this.getCustomersData();
    this.getCustomerRatingsData();
    this.getDuplicateData();
  },
  methods: {
    ...mapActions(["exportCustomers","getCustomers","getCustomerRatings","sendVerificationCode","registerCustomer","searchCustomerByPhoneNumber","updateCustomerDetails","updateCustomerStatus","getDuplicates","saveDuplicate","deleteDuplicate","mergeDuplicate"]),

    exportToExcel(){

      let self = this;
      this.isLoading = true;

      let outlet_id = 0;

      if(this.outlet){
        outlet_id = this.outlet.id;
      }


      this.exportCustomers({from_date:this.start_date,to_date:this.end_date,email: this.email_address})
        .then(function () {

          self.isLoading = false;

          self.$bvToast.toast("Export request received. We'll email you the report once its ready", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });

        })
        .catch(function (error) {

          self.isLoading = false;

          console.log(error);
        });
    },

    openEditCustomerModal(customer){

      this.customer = customer;

      this.$bvModal.show("edit-customer")

    },
    openMergeDuplicateModal(duplicate){

      this.duplicate = duplicate;

      this.$bvModal.show("merge-duplicate")

    },

    openDeleteDuplicate(duplicate){

      this.duplicate = duplicate;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete '+this.duplicate.name +' from system')
        .then(value => {

          if(value){
            self.confirmDeleteDuplicate();
          }

        })
        .catch(err => {
          // An error occurred
        })
    },

    confirmDeleteDuplicate(){
      this.isLoading = true;

      let self = this;

      this.deleteDuplicate(this.duplicate).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Duplicate deleted.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getDuplicateData();

      })
        .catch(error => {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }
        })

    },

    openUpdateCustomerStatus(status, customer){

      this.status = status;
      this.customer = customer;

      let self = this;
      this.$bvModal.msgBoxConfirm('Confirm customer status update ?')
        .then(value => {

          if(value){
            self.updateCustomerStatusData();
          }

        })
        .catch(err => {
          // An error occurred
        })
    },


    updateCustomerStatusData(){
      let self = this;

      this.isLoading = true;

      this.updateCustomerStatus({status: this.status, customerId: this.customer.id })
        .then(function () {
          self.isLoading = false;

          self.$bvToast.toast("Customer details updated successfully.", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });

          self.getCustomersData();
        })
        .catch(function (error) {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });

        })
    },

    onChange(v) {

    },

    changePage: function (page) {
      this.pagination.current_page = page;
      this.getCustomersData();
    },
    onComplete(v) {
      this.customerVerificationCode = v;
    },

    getDuplicateData(){
      let self = this;
      this.getDuplicates()
        .then(function (duplicates) {
          console.log(duplicates);
          self.duplicates = duplicates;
        })
        .catch(function (error) {

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },

    getCustomersData(){
      let self = this;
      this.getCustomers({page: this.pagination.current_page})
        .then(function (customers) {
          self.customers = customers.content;
          self.pagination.current_page = customers.number;
          self.pagination.total = customers.totalElements;
          self.pagination.per_page = customers.numberOfElements;
          self.pagination.from = customers.pageable.offset + 1 ;
          self.pagination.to = customers.pageable.pageSize;
          self.pagination.last_page = customers.totalPages ;
        })
        .catch(function (error) {


          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },
    getCustomerRatingsData(){
      let self = this;
      this.getCustomerRatings()
        .then(function (ratings) {
          self.ratings = ratings;
        })
        .catch(function (error) {

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },

    validateBeforeSubmitNewUser(){
         if(!this.phone_verified){
             this.sendVerificationCodeToCustomer();
         }
         else{
            this.enrollCustomer();
         }
    },

    validateBeforeUpdateCustomerDetails(){
      let self = this;

      this.isLoading = true;


      this.updateCustomerDetails(this.customer)
        .then(function () {

          self.isLoading = false;

          self.$bvToast.toast("Customer details updated successfully.", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });

          self.getCustomersData();


        })
        .catch(function (error) {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });
        })
    },

    mergeDuplicateData(){
      let self = this;

      this.isLoading = true;

      let mergeDuplicate = {
        phoneNumber: this.duplicate.phoneNumber,
        duplicateId: this.duplicate.id
      };


      this.mergeDuplicate(mergeDuplicate)
        .then(function () {

          self.isLoading = false;

          self.$bvToast.toast("Duplicate data merged successfully.", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });

          self.getDuplicateData();

        })
        .catch(function (error) {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });
        })
    },

    saveDuplicateData(){
      let self = this;

      this.isLoading = true;

      this.saveDuplicate(this.duplicate)
        .then(function () {

          self.isLoading = false;

          self.$bvToast.toast("Duplicate saved successfully.", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });

          self.getDuplicateData();


        })
        .catch(function (error) {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });
        })
    },

    enrollCustomer(){
      let self = this;

      this.isLoading = true;

      this.customer.mobile_number = this.pref+this.customer.mobile_number;

      this.registerCustomer(this.customer)
        .then(function () {

          self.isLoading = false;

          self.$bvToast.toast("Customer registered successfully.", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });

          self.getCustomersData();


        })
        .catch(function (error) {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });
        })
    },
    searchCustomer(){
        let self = this;

      self.isLoading = true;

      this.searchCustomerByPhoneNumber({phone_number: this.phoneNumber})
        .then(function (customers) {

          self.isLoading = false;

          self.customers = customers;
        })
        .catch(function (error) {

          self.isLoading = false;

        })


    },
    searchDuplicate(){
      let self = this;

      self.isLoading = true;

      this.searchCustomerByPhoneNumber({phone_number: this.phoneNumber})
        .then(function (customers) {

          self.isLoading = false;

          self.customers = customers;
        })
        .catch(function (error) {

          self.isLoading = false;

        })


    },
    sendVerificationCodeToCustomer(){

      let self = this;

      this.isLoading = true;

      this.sendVerificationCode({phoneNumber: this.pref+this.customer.mobileNumber})
        .then(function (verificationCode) {
          self.isLoading = false;

          self.$bvModal.show("add-verification-code");

          self.verificationCode = verificationCode;
        })
        .catch(function (error) {

          self.isLoading = false;

        })
    },
    verifyCode(){
      if(parseInt(this.customerVerificationCode) == parseInt(this.verificationCode)){
        this.phone_verified = true;
        this.$bvModal.show("add-customer");
      }
      else{
        this.$bvToast.toast("Verification does not match", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });
      }
    }
  }
};
</script>
